import React, { forwardRef } from "react";
import classes from "./Projects.module.scss";
import DisplayProject from "../Layout/DisplayProject";
import grandCommerceImg from "../../assets/img/grand-commerce.jpg";
import lockdownImg from "../../assets/img/lockdown-run.jpg";


const grandCommerceDetails = (
  <p>
    This is a fully responsive ecommerce webapp that allows users shop products
    online. State management was done with redux and I also built in a
    notification feature to give users feedback on their actions or if an error occurs.
  </p>
);

const lockdownDetails = (
  <p>
    This project consists of a single page app and an HTML5 runner game built in vanilla JavaScript. Both the app and game are coupled, sharing logic to give the user/player a nice, fun experience. Users can view their game details in the app and get bonus codes depending on how well they played the game. 
  </p>
);

const Projects = forwardRef((_props, ref) => {
  return (
    <div className={classes.container}>
      <div className={classes.content} ref={ref}>
        <h1><span>FEATURED</span> <span className={classes.desc_2}>PROJECTS</span></h1>
      <div className={classes.content}>
        <DisplayProject
          name="Grand Fashion"
          viewUrl="https://grand.com.ng"
          sourceUrl="https://github.com/toluls/grand-ecommerce"
          stack="JavaScript, React, Redux, React Router, CSS Modules, SASS (SCSS), Firebase"
          details={grandCommerceDetails}
          image={grandCommerceImg}
        />

        <DisplayProject
          name="Lockdown Run"
          viewUrl="https://game.toluls.com"
          sourceUrl="https://github.com/toluls/lockdown-run"
          stack="JavaScript, HTML5, CSS3, SASS (SCSS)"
          details={lockdownDetails}
          image={lockdownImg}
        />
      </div>
      </div>
    </div>
  );
});

export default Projects;
