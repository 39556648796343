import React, { useRef } from "react";
import classes from "./Main.module.scss";
import Header from '../Layout/Header';
import Welcome from "../Sections/Welcome";
import About from "../Sections/About";
import Projects from "../Sections/Projects";
import Contact from "../Sections/Contact";

const Main = () => {
  const aboutRef = useRef();
  const projectsRef = useRef();
  const contactRef = useRef();

  const scrollToContent = (content) => {
    content.current.scrollIntoView({top: '60px', behavior: "smooth" });
  };

  const handleAboutClick = () => {
    scrollToContent(aboutRef);
  };

  const handleProjectClick = () => {
    scrollToContent(projectsRef);
  };

  const handleContactClick = () => {
    scrollToContent(contactRef);
  };

  return (
    <>
      <Header
      onAboutClick={handleAboutClick}
      onProjectClick={handleProjectClick}
      onContactClick={handleContactClick}
      />
      <main className={classes.main}>      
        <Welcome />
        <div>
        <About ref={aboutRef} />
        <Projects ref={projectsRef} />
        <Contact ref={contactRef} />
        </div>
      </main>
    </>
  );
};

export default Main;
