import React, { forwardRef } from "react";
import classes from "./About.module.scss";

const techStack = "HTML5, CSS3, Tailwind CSS, Shadcn, MUI, JavaScript, TypeScript, SASS, React, Redux, NextJS, Gatsby, REST, GraphQL, Firebase, Serverless Architecture, GCP, AWS, SQL, GIT, JAMstack, Headless CMS, NodeJS, Express, MongoDB, Webflow, WordPress, Bootstrap, JQuery, JQuery Mobile, Postman, Jest, React Native, Expo".split(',');

const ExperienceItem = (props) => {
  return (
    <div className={classes.experience_item}>
      <a
        href={props.url}
        target="_blank"
        rel="noreferrer noopener"
        className={classes.link}
      >
        {props.title} <span>{`>>`}</span>
      </a>
      <p>{props.details}</p>
      <p className={classes.year}>{props.start} - {props.end}</p>
    </div>
  )
}

const About = forwardRef((_props, ref) => {
  return (
    <div className={classes.container}>      
      <div className={classes.content}>
          <h1><span>SOFTWARE</span> <span className={classes.desc_2}>ENGINEER</span></h1>
        <p>
          Hi! I'm  Tolulope, a creative and detail-oriented software engineer with focus on <span className={classes.whiteText} >frontend development</span>. I have about <span className={classes.whiteText}>seven years</span> experience in transforming ideas and designs into clean, elegant code and products using various technologies. For formal education, I have a Bachelor of Science in Psychology.
        </p>

        <p>
          I'm based in Lagos, Nigeria. However, location doesn't hinder my work as I have
          experience working remotely with individuals and teams in various
          countries.
        </p>

        <h2 className={classes.tools}>Technologies and Tools</h2>
        {techStack.map(techName => <h3 className={classes.tech}>{techName}</h3>)}
      </div>

      <div className={classes.experience} ref={ref}>
        <h1><span>7 YEARS OF</span> <span className={classes.desc_2}>EXPERIENCE</span></h1>
        <ExperienceItem
          title="Formula Monks (TheoremOne)"
          url="https://www.linkedin.com/company/46926"
          details="Spearheaded the frontend development of a large-scale commercial web application, consolidating four different apps into one to effectively improve the user experience. This required collaboration with cross-functional teams. I also implemented effective custom hooks that significantly optimized important project features."
          start="Oct 2022"
          end="Jul 2024"
          />
          <ExperienceItem
          title="Bejamas"
          url="https://www.linkedin.com/company/18595719"
          details="Implemented custom checkout requirements for a client project which led to a significantly better user experience in the checkout and order pages. I also developed user interfaces for websites and apps by coding to strict design specifications while working majorly with JavaScript, TypeScript, React, Redux and NextJS in an agile environment."
          start="Sep 2021"
          end="Aug 2022"
          />    
          <ExperienceItem
          title="Squared Ace Solutions"
          url="https://www.linkedin.com/company/11036120"
          details="Proposed and led the team that migrated a major client’s website from WordPress to react. The result of the implementation was a better reactive UI/UX and over 50% increase in revenue for the client. I worked on projects using react, wordpress and static websites using HTML5, CSS3, Javascript, Typescript, JQuery and bootstrap. "
          start="Apr 2017"
          end="Aug 2021"
          />             
      </div>
    </div>
  );
});

export default About;
