import React from 'react';
import tolulope from '../../assets/img/Tolulope_Saliu.jpg';
import classes from './Welcome.module.scss';

const Welcome = () => {
  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={tolulope} alt="Tolulope Saliu" />
      </div>
      <div className={classes.content}>
        <h1>Tolulope</h1>
        <p className={classes.text}>A detail-oriented software engineer with over seven years experience developing effective solutions.</p>
        <div className={classes.links}>
          <div className={classes.topLinks}>
            <a href="https://linkedin.com/in/tolulopeSaliu" target="_blank" rel="noreferrer noopener">LinkedIn</a>
            <a href="https://github.com/toluls" target="_blank" rel="noreferrer noopener">GitHub</a>
          </div>
          <a className={classes.bottomLink} href="/Tolulope_Saliu_Resume.pdf" target="_blank" rel="noreferrer noopener">Download Resume</a>
        </div>
      </div>
    </div>
  );
}

export default Welcome;

