import React from 'react';
import classes from './Header.module.scss';

const Header = (props) => {
  return (
    <header className={classes.header}>
      <div className={classes.section}>
        <button className={classes.section_item} onClick={props.onAboutClick}>Experience</button>        
        <button className={classes.section_item} onClick={props.onProjectClick}>Projects</button>        
        <button className={classes.section_item} onClick={props.onContactClick}>Contact</button>        
      </div>
    </header>
  );
}


export default Header;