import React from 'react';
import classes from './App.module.scss';
import Main from './Components/Layout/Main';
import Footer from './Components/Layout/Footer';

const App = () => {
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Main />
        <Footer />
      </div>
    </div>
  );
}

export default App;